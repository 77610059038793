<template>
  <div>
    <list-layout ref="govAlbum" :filter-form="filterForm" :thead="thead"
                 :on-fetch="getList" :on-edit="onEdit"
    >
      <template #filter>
        <detail-list-filter is-gov :filterForm="filterForm" :uploadFilter="ok" />
      </template>
      <template #item_is_show="{row}">{{row.is_show ? '显示': '隐藏'}}</template>
      <template #item_cover="{row}">
        <list-image :src="row.cover" :height="150" is-image />
      </template>
    </list-layout>
  </div>
</template>

<script>
import ListLayout from "@/base/layout/ListLayout";
import ListImage from "@/base/components/List/ListImage";
import DetailListFilter from "@/modules/activity/components/activity-album/DetailListFilter";
import { getGovAlbumList } from "@/modules/gov/api/album-library";

export default {
  components: {DetailListFilter, ListImage, ListLayout},
  data() {
    return {
      filterForm: {
        id: "",
        page_size: 15,
        keyword: "",
        photo_category_id: "",
        type: -1,
        project_id: ''
      },
      thead: [
        {label: "所属商协会", prop: "project_name", minWidth: 100},
        { label: "相册名称", prop: "activity_name", minWidth: 150 },

        //   { label: "封面图", prop: "cover", minWidth: 110 },
        {
          label: "图片数量",
          prop: "photo_image_count",
          minWidth: 120,
        },
        {
          label: "数据类型",
          prop: "type_text",
          minWidth: 110,
        },
        {
          label: "分类名称",
          prop: "category_name",
          minWidth: 110,
        },
        // { label: "排序", prop: "sort" },
        { label: "显示", prop: "is_show", type: 'slot' },
        { label: "创建时间", prop: "create_time" , minWidth: 200},
      ]
    }
  },
  methods: {
    onEdit(row) {

    },
    getList(q) {
      return getGovAlbumList(q)
    },
    // 筛选回调
    ok(e) {
      const pageData = { ...this.filterForm, ...e, page: 1 };
      this.$refs.govAlbum.getList(pageData)
    },
  },
}
</script>

<style scoped>

</style>
